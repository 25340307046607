<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

import {
  required,
  email,
  minLength
} from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
  page: {
    title: "Form Create Staff",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Form Create Staff",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "Staff",
          href: "/staff"
        },
        {
          text: "Create Staff",
          active: true
        }
      ],
      form: {
        name: null,
        phone: null,
        password: null,
        email: null,
        role: null,
        location: null
      },
      roles: [],
      isRoleNotSelected: false,
      typesubmit: false,
      isError: false,
      errorMessage: null,
      locations: [],
      islocationsNotSelected: false,
    };
  },
  validations: {
    form: {
      name: { required },
      phone: { required },
      password: { required, minLength: minLength(8) },
      email: { required, email }
    }
  },
  mounted() {
    this.fetchRoles()
    this.fetchLocation()
  },
  methods: {
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
      } else {
        this.isRoleNotSelected = this.form.role === null ? true : false;
        if (this.isRoleNotSelected) {
          return false;
        }
        if (this.form.role.name != 'admin' && this.form.role.name != 'finance' && this.form.role.name != 'manager') {
          this.islocationsNotSelected = this.form.location === null ? true : false;
          if (this.islocationsNotSelected) {
            return false;
          }
        }
        // do your submit logic here
        let param = new FormData();
        param.append('name', this.form.name) 
        param.append('email', this.form.email)
        param.append('phone', this.form.phone)
        param.append('password', this.form.password) 
        param.append('role', this.form.role.roleId)
        if (this.form.role.name != 'admin' && this.form.role.name != 'finance' && this.form.role.name != 'manager') {
          param.append('location', this.form.location.locationId)
        }

        this.$axios.post('/staff', param)
        .then( (result) => { 
          console.log(result.data)
          Swal.fire("Success", "Staff Created Successfully", "success");
          this.$router.push('/staff')
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    },
    fetchRoles() {
      this.$axios
        .get(`role`, {
          params: {
            sortBy: "name",
            sortType: 1,
            limit: 500
          },
        })
        .then((response) => {
          console.log(response)
          this.roles = response.data.result.role;
        });
    },
    fetchLocation() {
      this.$axios
        .get(`location`)
        .then((response) => {
          let result = response.data.result.location;
          this.locations = result;
        });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
            >{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">
              <div class="form-group">
                <label>Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  name="name"
                  :class="{ 'is-invalid': typesubmit && $v.form.name.$error }"
                />
                <div v-if="typesubmit && $v.form.name.$error" class="invalid-feedback">
                  <span v-if="!$v.form.name.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Phone</label>
                <input
                  v-model="form.phone"
                  type="number"
                  class="form-control"
                  name="phone"
                  :class="{ 'is-invalid': typesubmit && $v.form.phone.$error }"
                />
                <div v-if="typesubmit && $v.form.phone.$error" class="invalid-feedback">
                  <span v-if="!$v.form.phone.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>E-Mail Address</label>
                <div>
                  <input
                    v-model="form.email"
                    type="email"
                    name="email"
                    class="form-control"
                    :class="{ 'is-invalid': typesubmit && $v.form.email.$error }"
                  />
                  <div v-if="typesubmit && $v.form.email.$error" class="invalid-feedback">
                    <span v-if="!$v.form.email.required">This value is required.</span>
                    <span v-if="!$v.form.email.email">This value should be a valid email.</span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>Password</label>
                <div>
                  <input
                    v-model="form.password"
                    type="password"
                    name="password"
                    class="form-control"
                    :class="{ 'is-invalid': typesubmit && $v.form.password.$error }"
                  />
                  <div v-if="typesubmit && $v.form.password.$error" class="invalid-feedback">
                    <span v-if="!$v.form.password.required">This value is required.</span>
                    <span v-if="!$v.form.password.minLength">Password must be at least 8 characters.</span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>Role</label>
                <multiselect :class="{ 'is-invalid': isRoleNotSelected }" v-model="form.role" deselect-label="Can't remove this value" track-by="name" label="name" placeholder="Select one" :options="roles" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                </multiselect>
                <div v-if="isRoleNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>
              
              <div class="form-group" v-if="form.role !== null && form.role.name != 'admin' && form.role.name != 'finance' && form.role.name != 'manager'">
                <label>Location</label>
                <multiselect
                  :class="{ 'is-invalid': islocationsNotSelected }"
                  v-model="form.location"
                  label="name"
                  track-by="locationId"
                  placeholder="Select One"
                  open-direction="bottom"
                  :maxHeight="500"
                  :options="locations"
                  :multiple="false"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :hide-selected="false"
                  :allowEmpty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.name }}</strong>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                <div v-if="islocationsNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>

              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>